// COLORS
$black: #263b47;
$blue: #036ab5;
$blue2: #d8e3ec;
$white: #ffffff;

// BREAKPOINTS
$large: 1920px;
$big: 1920px;
$medium: 1024px;
$small: 600px;

$mobile_maket_width: 390px;
// coefficient is needed since actual layouts
// are bigger than figma layouts
$smallCoef: calc($small / $mobile_maket_width);

$max_width: 1200px;
