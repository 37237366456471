@use "../../styles/vars.module" as vars;

.container {
  height: 50vh;
  width: 100%;
  padding: 10px;
  border-radius: 15px;
  border: 2px solid vars.$blue;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.logo {
  width: 200px;
  color: vars.$blue;
}
