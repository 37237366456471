@use "../../styles/vars.module" as vars;

.container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 0;
}

.lang-container {
  display: flex;
  gap: 15px;
  padding-top: 10px;
}
