@use "../../styles/vars.module" as vars;

.container {
  display: inline-flex;
  flex-direction: column;
  color: vars.$black;
  text-decoration: none;

  & > span {
    font-size: 12px;
  }
}

.logo-container {
  display: flex;
  gap: 10px;
}

.logo {
  width: 120px;
  height: auto;
  color: vars.$black;
  @media screen and (max-width: 600px) {
    width: 100px;
  }
}

.info-icon {
  cursor: pointer;
  &:hover {
    color: vars.$blue;
  }
}
