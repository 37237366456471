.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.video-container {
  display: inline-flex;
  max-width: calc(100vw - 20px);
  & > video {
    display: none;
  }
  & > canvas {
    object-fit: contain;
    object-position: left top;
    min-height: 50vh;
    max-height: 70svh;
  }
}

.btn-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
}

.data-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
